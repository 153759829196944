<template>
    <div class="site-main site-candidates">
        <div class="candidates-main">
            <div class="search-bars">
                <router-link class="search-bar" to="/candidates/enterprise" v-show="candidateUrl == '/candidates/enterprise'">
                    <span class="bar-title">企业人才库</span>
                </router-link>
                <router-link class="search-bar" to="/candidates/firmCandidate" v-show="candidateUrl == '/candidates/firmCandidate'">
                    <span class="bar-title">企业人才库</span>
                </router-link>
                <router-link class="search-bar" to="/candidates/personal">
                    <span class="bar-title">我的人才库</span>
                </router-link>
                <router-link class="search-bar" id="name-list-tab" v-show="isCFuser" to="/candidates/namelist">
                    <span class="bar-title">简易人才库</span>
                </router-link>
                <router-link class="search-bar" v-show="isCFuser" to="/candidates/linkedin">
                    <span class="bar-title">领英人才库</span>
                </router-link>
            </div>
            <keep-alive>
                <router-view :page-view-id="pageViewId"></router-view>
            </keep-alive>
        </div>

        <!-- <footer-container></footer-container> -->
    </div>
</template>
<script>
import FooterContainer from '@/hr/component/footer.vue';

export default {
    name: 'FirmCandidate',
    components:{
        FooterContainer
    },
    data(){
        return{
            pageViewId:_uuid
        }
    },
    computed:{
        isCFuser(){
            return this.$store.state.user.userInfo.isCFUser;
        },
        candidateUrl() {
            return this.$store.state.candidateListUrl.candidateListUrl;
        }
    },
    mounted(){}
}
</script>

<style lang="scss" scope>
.candidates-main{
    min-width: 1180px;
    padding-bottom: 56px;
    
    .search-bars {
        background-color: #fff;
        border-radius: 5px 5px 0 0;
        .search-bar.router-link-active{
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 50px;
                height: 3px;
                margin-left: -25px;
                background-color: $primary;
            }
            // background-color: #f8f8f8;
            // border-bottom: 4px solid #38bc9c;
            .bar-title{
                display: inline-block;
                height: 52px;
                line-height: 52px;
                font-size: 16px;
                color: #38bc9c;
            }
        }
    }
}
.last-viewed-wrap {
    margin-top: 46px;
}
.create-candidate-url {
    display: block;
    padding: 12px;
    text-align: center;
    font-size: 16px;
    color: $primary;
    border: 1px solid $primary;

    &:hover {
        color: #fff;
        background-color: $primary;
    }
}
.col-right-board{
    margin-top: -40px;
}
.col-right-board-filter {
    margin-top: 0;
    margin-left: 25px;
    width: 324px;
    padding: 0;
    min-height: 0;
}
.search-bars{
    position: relative;
    font-size: 0;
    border-bottom: 1px solid #EEEEEE;

    .search-bar{
        // height: 60px;
        // line-height: 60px;
        // width: 110px;
        margin-left: 65px;
        display: inline-block;
        position: relative;
        user-select: none;
        text-align: center;
        margin-right: 3px;
        &.active{
            // background-color: #f8f8f8;
            .bar-title{
                color: #38bc9c;
            }
        }
        &:hover{
            cursor: pointer;
        }
    }
    .bar-title{
        font-size: 14px;
        color: #333;
        line-height: 40px;
    }

    .name-list-menu {
        display: none;
    }

}
</style>

<style lang="scss">
body .site-candidates{
    height: 100%;
    overflow-y: auto;
    padding: 15px 15px 10px;
    border-radius: 8px;
}
</style>