var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "site-main site-candidates" }, [
    _c(
      "div",
      { staticClass: "candidates-main" },
      [
        _c(
          "div",
          { staticClass: "search-bars" },
          [
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.candidateUrl == "/candidates/enterprise",
                    expression: "candidateUrl == '/candidates/enterprise'",
                  },
                ],
                staticClass: "search-bar",
                attrs: { to: "/candidates/enterprise" },
              },
              [_c("span", { staticClass: "bar-title" }, [_vm._v("企业人才库")])]
            ),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.candidateUrl == "/candidates/firmCandidate",
                    expression: "candidateUrl == '/candidates/firmCandidate'",
                  },
                ],
                staticClass: "search-bar",
                attrs: { to: "/candidates/firmCandidate" },
              },
              [_c("span", { staticClass: "bar-title" }, [_vm._v("企业人才库")])]
            ),
            _c(
              "router-link",
              {
                staticClass: "search-bar",
                attrs: { to: "/candidates/personal" },
              },
              [_c("span", { staticClass: "bar-title" }, [_vm._v("我的人才库")])]
            ),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCFuser,
                    expression: "isCFuser",
                  },
                ],
                staticClass: "search-bar",
                attrs: { id: "name-list-tab", to: "/candidates/namelist" },
              },
              [_c("span", { staticClass: "bar-title" }, [_vm._v("简易人才库")])]
            ),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCFuser,
                    expression: "isCFuser",
                  },
                ],
                staticClass: "search-bar",
                attrs: { to: "/candidates/linkedin" },
              },
              [_c("span", { staticClass: "bar-title" }, [_vm._v("领英人才库")])]
            ),
          ],
          1
        ),
        _c(
          "keep-alive",
          [_c("router-view", { attrs: { "page-view-id": _vm.pageViewId } })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }